.general-styling {
  height: 100vh;
  width: 100vw;
  display: flex;
  background-color: var(--background-color, #eee) !important;
  color: var(--color, #111) !important;
  justify-content: center;
  align-items: center;
  overflow: auto;
}

.output {
  margin: 1rem 0;
}

textarea {
  resize: none !important;
  width: 100%
}

.dark {
  --background-color: #111;
  --color: #eee;
}

.sun {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #000;
  font-size: 40px;
  animation: rotate 300s linear infinite;
  animation-delay: 300s;

}

@keyframes rotate {
  0% {
    transform: rotate(0deg)
  }

  1% {
    transform: rotate(360deg)
  }

  100% {
    transform: rotate(360deg)
  }
}

.sun-dark {
  color: #fff;
}

.grid {
  display: grid;
  grid-template-rows: 1fr 1fr 18fr;
  width: 100%;
  height: 100%;
  justify-items: center;
  align-items: center;
  gap: 1rem;
}

@media screen and (max-width: 480px) {
  .grid
  {
    grid-template-rows: 1fr 1fr 18fr 7fr 5fr;
  }
}

.flex{
  display: flex;
  height: 100%;
  max-height: 90vh;
  gap: 1rem;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
}

.sub-grid {
  overflow-x: auto;
  height: 50rem;
  border: 1px solid #8888;
  display: grid;
  width: 50vh;
  max-width: 90vw;
  grid-auto-rows: 4rem;
  justify-items: center;
  align-items: center;
}

.other-grid {
  height: 100%;
  display: grid;
  grid-template-columns: 15rem 20rem;
  gap: 2rem;
  flex-direction: row;
}

.neat{
  display:flex;
  flex-direction: column;
  justify-content: space-between
}

.sub-flex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.discord-view {
  background-color: #37393f;
  color: #dcddde;
  width: 100%;
}

.button {
  width: 10rem
}

.only-small {
  display: none;
}

@media only screen and (max-width: 700px) {
  .only-small {
    display: block;
  }
}

.item {
  display: grid;
  grid-template-columns: 5fr 14fr 5fr;
}
